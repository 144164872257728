<template>
    <div class="w-full h-full flex items-center justify-center bg-body">
        <div>
            <div>
                <!-- <img class="mx-auto w-24" alt="Vue logo" src="@/assets/logo.png"> -->
                <div class="mt-2 text-center leading-tight flex flex-wrap items-center content-center justify-center">
                    <img src="/img/logo.png" alt="" width="auto" />
                </div>
            </div>

            <Request
                model="Auth"
                action="login"
                :form="form"
                @success="onSuccess"
                @error="onError"
                v-slot="{ request, loading }"
            >
                <div @keypress.enter="request" class="mt-6 w-full max-w-xs px-4 py-4 box rounded-lg">
                    <label class="block">
                        <p class="text-sm text-gray1">Email</p>
                        <input type="email" v-model="form.email" class="login-field-input" />
                    </label>

                    <label class="mt-2 block">
                        <p class="text-sm text-gray1">Password</p>
                        <input type="password" v-model="form.password" class="login-field-input" />
                    </label>

                    <div class="mt-4 flex flex-wrap items-center content-center justify-center">
                        <div
                            @click="request"
                            class="
                                button
                                w-auto
                                px-4
                                py-1
                                bg-blue
                                font-medium
                                text-indigo-100
                                rounded-lg
                                text-center
                                mx-auto
                            "
                            :class="{ 'opacity-50 pointer-events-none': loading }"
                        >
                            Login
                        </div>
                    </div>
                </div>
            </Request>
        </div>
    </div>
</template>

<script>
    import { Request } from '@/api/components';
    import { actions,state } from '@/store';
    import Auth from '/src/api/models/Auth.js';

    export default {
        name: 'Login',
        components: {
            Request,
        },
        data() {
            return {
                form: {
                    email: '',
                    password: '',
                },
            };
        },
        computed: {
            user() {
                return state.user;
            },
            token() {
                return state.token;
            }
        },
        methods: {
            async onSuccess(response) {
                console.log('this.user :>> ', this.user);
                
                await actions.setToken(response.data.token);
                //console.log('this.token :>> ', this.token);
                const data = await Auth.me();
                actions.setUser(data);

                this.$router.push({ name: this.user.roles[0] }).catch(() => ({}));
                
                                
            },
            
            onError(error) {
                console.log('failed', error);
            },
        },
    };
</script>
<style>
    .login-field-input {
        border-radius: 40px;
        padding: 3px 10px;
        color: #c9bb9a !important;
        margin-top: 5px;
        border: 2px solid #1e232e !important;
        background-color: rgb(37, 51, 67) !important;
        filter: none;
    }

    .login-field-input:-webkit-autofill,
    .login-field-input:-webkit-autofill:hover,
    .login-field-input:-webkit-autofill:focus,
    .login-field-input:-webkit-autofill:active {
        border-radius: 40px;
        padding: 3px 10px;
        color: #c9bb9a !important;
        margin-top: 5px;
        border: 2px solid #1e232e !important;
        -webkit-box-shadow: 0 0 0 100px rgb(37, 51, 67) inset !important;
        -webkit-text-fill-color: #c9bb9a !important;
        background-color: rgb(37, 51, 67) !important;
        background-clip: content-box !important;
        filter: none;
    }
</style>
